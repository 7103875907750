import { useFormik } from "formik";
import { IBrandingProps, ICommonOptions, ICommonReturnType, ICreateBrandingParams, IThemeListProps } from "../../interfaces/interfaceCommon"
import Stack from "@mui/material/Stack/Stack";
import { InputBox } from "../common/UI/inputBox/inputBox";
import Button from "@mui/material/Button/Button";
import SelectComponent from "../common/UI/select/selectComponent";
import { ColorPickerInput } from "../common/colorPicker/colorPicker";
import { useEffect, useState } from "react";
import config from "../../constants/config";
import getService from "../../services/getService";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import { useMutation } from "react-query";
import postService from "../../services/post";
import toast from "react-hot-toast";
import { createBrandingSchema } from "../../validations/createBrandingSchema";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import putService from "../../services/putService";
import { ColorPallateOptions } from "../../utils/enums";
import { displayForEditedRecord, displayForParams } from "../../utils/utils";
import { displayOptions } from "../../constants/data";

const brandingType: ICommonOptions[] = [
    { id: 0, name: "Color" },
    { id: 1, name: "Text" }
]

export const AddBranding: React.FunctionComponent<IBrandingProps> = ({ refetch, closeModal, modalId, id }) => {
    const [showColorPallate, setColorPallate] = useState(false);
    const [themeData, setThemeData] = useState([]);
    const [editRecordData, setEditRecordData] = useState<ICreateBrandingParams>({} as ICreateBrandingParams);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchThemeList();
        !!id && fetchEditedRecords();
    }, []);

    const fetchEditedRecords = async () => {
        setIsLoading(true);
        try {
            const url = `${config.cmsConfig.apiEndpoint.editBrand}/${id}`;
            const result: ICommonReturnType = await getService(url);
            setEditRecordData(result.data);
            setColorPallate(result.data.type === ColorPallateOptions.COLOR ? true : false)
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }

    }


    const fetchThemeList = async () => {
        try {
            const url = config.cmsConfig.apiEndpoint.themeList;
            const result: ICommonReturnType = await getService(url);
            const themeList = result?.data.map((theme: IThemeListProps) => {
                return {
                    name: theme.name,
                    id: theme.id
                }
            })
            setThemeData(themeList);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const editedDataInitialState = {
        title: editRecordData?.title || "",
        type: brandingType.filter((val) => val.name === editRecordData.type)[0]?.id || 0,
        attributeKey: editRecordData.attributeKey || "",
        attributeValue: editRecordData.attributeValue || "",
        themeId: Number(!!editRecordData.themeId ? editRecordData.themeId : -1),
        sort: !!editRecordData.sort ? editRecordData.sort : 0,
        isMobile: editRecordData?.isMobile || false,
        isActive: editRecordData?.isActive || false,
        displayFor: displayForEditedRecord(editRecordData.displayFor),
    }

    const initialValues = !!id ? editedDataInitialState : {
        title: "",
        type: -1,
        attributeKey: "",
        attributeValue: "#000",
        themeId: -1,
        sort: 0,
        isMobile: false,
        isActive: false,
        displayFor: ""
    }


    const { values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, dirty, resetForm, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: createBrandingSchema,
        onSubmit: (values) => {
            const typesArr = brandingType.filter((value: ICommonOptions) => value.id === Number(values.type));
            const params: ICreateBrandingParams = {
                themeId: Number(values.themeId),
                title: values.title,
                type: typesArr[0].name,
                attributeKey: values.attributeKey,
                attributeValue: values.attributeValue,
                sort: values.sort,
                isMobile: values.isMobile,
                displayFor: displayForParams(values.displayFor),
                isActive: values.isActive
            }
            createBrandMutation.mutate(params);
        },
    });

    const createBrandMutation = useMutation({
        mutationFn: async (param: ICreateBrandingParams) => {
            const url = !!id ? `${config.cmsConfig.apiEndpoint.updateBrand}/${id}` : config.cmsConfig.apiEndpoint.createBrand;
            try {
                const result = !!id ? await putService(url, param) : await postService(url, param);
                return result;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: (data) => {
            refetch();
            closeModal(modalId);
            toast.success(data.message);
            resetForm();
        },
        onError: (error: any) => {
            toast.error('Failed to create branding ' + error.response?.data?.message);
        },
    });

    const handleType = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedValue = event.target.value as string;
        setFieldValue('type', selectedValue);
        setColorPallate(Number(selectedValue) === 0 ? true : false);
    }

    const resetEntireForm = () => {
        resetForm();
        setColorPallate(false);
    }

    return (isLoading ? <Stack minHeight={300}>{<LoadingSpinner small={true} />}</Stack> :
        <div className="model-inner-margintop">
            <form onSubmit={handleSubmit}>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.title} name="title" id={"title"} label={"Title"} variant="outlined" errorMessage={errors.title} isTouched={touched.title} />
                    </Stack>
                    <Stack flex={1}>
                        <SelectComponent
                            value={values.themeId}
                            options={themeData}
                            size="medium"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="themeId"
                            name="themeId"
                            label="Theme"
                            labelId="themeId"
                            errorMessage={errors.themeId}
                            touched={touched.themeId}
                        />
                    </Stack>
                </Stack>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <SelectComponent
                            value={values.type}
                            options={brandingType}
                            size="medium"
                            onChange={handleType}
                            onBlur={handleBlur}
                            id="type"
                            name="type"
                            label="Type"
                            labelId="type"
                            errorMessage={errors.type}
                            touched={touched.type}
                        />
                    </Stack>
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.attributeKey} name="attributeKey" id={"attributeKey"} label={"Attribute Key"} variant="outlined" errorMessage={errors.attributeKey} isTouched={touched.attributeKey} />
                    </Stack>
                </Stack>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    {showColorPallate ? <Stack flex={1}>
                        <ColorPickerInput
                            value={values.attributeValue}
                            onChange={handleChange}
                            name="attributeValue"
                        />
                    </Stack> : <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.attributeValue} name="attributeValue" id={"attributeValue"} label={"Attribute Value"} variant="outlined" errorMessage={errors.attributeValue} isTouched={touched.attributeValue} />
                    </Stack>
                    }
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.sort} name="sort" id={"sort"} label={"Sort"} variant="outlined" errorMessage={errors.sort} isTouched={touched.sort} />
                    </Stack>
                </Stack>


                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <SelectComponent
                            value={values.displayFor === ''? -1 :values.displayFor}
                            options={displayOptions}
                            size="medium"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="displayFor"
                            name="displayFor"
                            label="Display for"
                            labelId="displayFor"
                            errorMessage={errors.displayFor}
                            touched={touched.displayFor}
                        />
                    </Stack>
                    <Stack flex={1} flexDirection={"row"} gap={2}>
                        <FormControlLabel control={
                                   <Checkbox
                                checked={values.isMobile}
                                onChange={handleChange}
                                name="isMobile"
                              
                            />}
                            sx={{
                                "& .MuiCheckbox-root":{
                                    color:"#5688F7"
                                }
                            }}
                            label="Mobile" />
                        <FormControlLabel control={
                               <Checkbox
                                checked={values.isActive}
                                onChange={handleChange}
                                name="isActive"
                            />}
                            sx={{
                                "& .MuiCheckbox-root":{
                                    color:"#5688F7",
                                }
                            }}
                            label="Active" />
                    </Stack>
                </Stack>

                <div className={`button_Wrapper`}>
                    <Button className="customBtn" disabled={!dirty} type="reset" onClick={() => resetEntireForm()} size="small" variant="outlined">
                        {"Reset"}
                    </Button>
                    <Button className="customBtn" disabled={!isValid || !dirty} type="submit" size="small" variant="outlined">
                        {"submit"}</Button>
                </div>
            </form>
        </div>
    )
}