import React from 'react';
import { FormControl } from '@mui/material';
import { IColorPickerInputProps } from '../../../interfaces/interfaceCommon';

export const ColorPickerInput: React.FC<IColorPickerInputProps> = ({ value, onChange, ...props }) => {
    return (
        <FormControl fullWidth>
            <input
                type="color"
                {...props}
                value={value}
                onChange={onChange}
                style={{
                    width: '100%',
                    height: '9px',
                    border: 'none',
                    cursor: 'pointer',
                    appearance: 'none', // Removes default browser styling
                    backgroundImage: 'linear-gradient(to right, red, orange, yellow, green, cyan, blue, violet)', // Color spectrum
                    // backgroundSize: '100% 100%',
                }}
            />
        </FormControl>
    );
};
