import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IUploadButtonProps } from '../../../interfaces/interfaceCommon';
import { useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack/Stack';
import classes from "./UploadButton.module.scss";
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const UploadButton: React.FunctionComponent<IUploadButtonProps> = ({ onChange, label, imgName, imgUrl, setHasImageRemoved, setHasMobileImageRemoved }) => {

    const [fileName, setFileName] = useState(imgName || "");
    const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(imgUrl || null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            onChange(file);
            setFileName(file.name);

            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemove = () => {
        setFileName("");
        setImageSrc(null);
        onChange(null);
        setHasImageRemoved && setHasImageRemoved(true);
        setHasMobileImageRemoved && setHasMobileImageRemoved(true);

        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    return (
        <Stack>
            <Button
                component="label"
                startIcon={<CloudUploadIcon />}
                // variant="outlined"
                sx={{border:"2px dashed #5688F7"}}
            >
                {label}
                <VisuallyHiddenInput ref={fileInputRef} type="file" onChange={handleChange} accept="image/png, image/jpeg, image/jpg image/webp, image/gif" />
            </Button>
            {fileName && (
                <div className={classes.fileInfo}>
                    {imageSrc && (
                        <div className={classes.imagePreview}>
                            <img src={imageSrc as string} alt="Preview" className={classes.thumbnail} />
                            <IconButton className={classes.deleteButton} onClick={handleRemove}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    )}
                    <div className={classes.imageName}>{fileName}</div>
                </div>
            )}
        </Stack>
    );
}