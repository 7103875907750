import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { images } from "../../../constants";
import SidebarContext from "../../../store/sidebarContext";
import classes from "./Sidebar.module.scss";
// import LogoutIcon from '@mui/icons-material/Logout';
import config from "../../../constants/config";
import { ISideBarNav } from "../../../interfaces/configInterface";
import { removeLocalStorageData } from "../../../utils/utils";
import postService from "../../../services/post";
import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import { useModulePrivilage } from "../../../store/modulePrivilageContext";
import LogoutIcon from "../../../assets/images/logout.svg"


function Sidebar() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindowSize();
  const location = useLocation();
  const sidebarCtx = useContext(SidebarContext);
  const { moduleList } = useModulePrivilage();
  let sidebarNav: ISideBarNav[] = getRoleBasedSideNavOptions();
  const navigate = useNavigate();

  function getRoleBasedSideNavOptions() {
    const sidebarNavArr: ISideBarNav[] = config.cmsConfig.sidebarNav.filter((sidebarItem, index) => 
      index === 0 || moduleList.some((serverItem:any )=> serverItem.name === sidebarItem.text)
    );
    return sidebarNavArr; 
  }

  function openSidebarHandler() {
    //for width>768(tablet size) if sidebar was open in width<768 was opened too.
    //just in case of tablet size and smaller then, sidebar__open can added.
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  function logoutHandler() {
    logOut();
    openSidebarHandler();
  }

  const logOut = async () => {
    try {
      const url = `${process.env.REACT_APP_CMS}${config.cmsConfig.apiEndpoint.logoutApiUrl}`;
      const result = await postService(url, {});
      return result;
    } catch (error) {
      throw error;
    } finally {
      removeLocalStorageData();
      window.location.href = "/login";
    }
  }

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  return (
    <div
      className={`${classes.sidebar} ${!sidebarCtx.isOpen && classes.sidebar_close
        }`}
    >
      <div className={classes.sidebar__logo} onClick={() => {navigate("/")}} style={{ cursor: "pointer" }}>
        {sidebarCtx.isOpen ? <img className={classes.logoDesktop} src={images.logo} alt="jontech" /> : <img className={classes.logoMobile} src={images.logoMobile} alt="jontech" />}
      </div>
      <div className={classes.sidebar__menu}>
        {sidebarNav.map((nav, index) => (
          <Link
            to={nav.link}
            key={`nav-${index}`}
            className={`${classes.sidebar__menu__item} ${activeIndex === index && classes.active
              }`}
            onClick={openSidebarHandler}
            onMouseEnter={() => setHoverIndex(index)} 
            onMouseLeave={() => setHoverIndex(null)} 
          >
            <div className={classes.sidebar__menu__item__icon}>
            <Tooltip title={nav.text} placement="right-start">
             
              {activeIndex === index || hoverIndex === index ?  (
                <img src={nav.activeIcon}  alt={nav.text}  />
              ) : (
                <img src={nav.icon} alt={nav.text} />
              )}
            </Tooltip>
            </div>
            
          

            <div className={classes.sidebar__menu__item__txt}>
              {nav.text}
            </div>
          </Link>
        ))}
        <Link
          to="/login"
          className={classes.sidebar__menu__item}
          onClick={logoutHandler}
        >
          <div className={classes.sidebar__menu__item__icon}>
            <Tooltip title={'Logout'} placement="right-start">
            
            <img src={LogoutIcon} alt="Logout" />
      
            </Tooltip>
          </div>
          <div className={classes.sidebar__menu__item__txt}>{"Logout"}</div>
        </Link>
      </div>

      {/* <div className={[classes.sidebar__menu, classes.logout].join("")}>
        <Link
          to="/login"
          className={classes.sidebar__menu__item}
          onClick={logoutHandler}
        >
          <div className={classes.sidebar__menu__item__icon}>
            <LogoutIcon />
          </div>
          <div className={classes.sidebar__menu__item__txt}>{"logout"}</div>
        </Link>
      </div> */}
    </div>
  );
}

export default Sidebar;
