import { useFormik } from "formik";
import { ICommonReturnType, ICreateNavigation, ICreateThemeProps, IGameData, IGameOptions, ILanguageListProps, IThemeListProps } from "../../interfaces/interfaceCommon";
import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { InputBox } from "../common/UI/inputBox/inputBox";
import config from "../../constants/config";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import SelectComponent from "../common/UI/select/selectComponent";
import { useEffect, useState } from "react";
import getService from "../../services/getService";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import putService from "../../services/putService";
import { createNavigationSchema } from "../../validations/createNavigationSchema";
import { UploadButton } from "../common/actionButtons/UploadButton";
import postFormDataService from "../../services/postFormData";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useGames } from "../../store/gamesContext";

const displayOptions = [
    { id: 3, name: "Pre-login" },
    { id: 4, name: "Post-login" },
    { id: 5, name: "Both" },
];

export const AddNavigation: React.FC<ICreateThemeProps> = ({ closeModal, modalId, refetch, id }) => {
    const [editRecordData, setEditRecordData] = useState<ICreateNavigation>({} as ICreateNavigation);
    const [isLoading, setIsLoading] = useState(false);
    const [languageData, setLanguageData] = useState([]);
    const [themeData, setThemeData] = useState([]);
    const [gameOptions, setGameOptions] = useState<IGameOptions[]>([]);
    const [gamesData, setGamesData] = useState<IGameData[]>([]);
    const { games } = useGames();

    useEffect(() => {
        fetchGameRecords();
        fetchLanguageList();
        fetchThemeList();
        !!id && fetchEditRecords();
    }, []);

    async function fetchGameRecords() {
        const mappedArr: IGameOptions[] = games && games.map((game: any, index: number) => {
            return {
                label: `${game.GameName}-${index}`,
                id: index
            }
        });
        setGameOptions(mappedArr);
        setGamesData(games);
    }

    async function fetchEditRecords() {
        setIsLoading(true);
        try {
            const url = `${config.cmsConfig.apiEndpoint.editNavigation}/${id}`;
            const result: ICommonReturnType = await getService(url);
            setEditRecordData(result.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    const fetchLanguageList = async () => {
        try {
            const url = config.cmsConfig.apiEndpoint.languageList;
            const result: ICommonReturnType = await getService(url);
            const languageList = result?.data.map((language: ILanguageListProps) => {
                return {
                    name: language.name,
                    id: language.id
                }
            })
            setLanguageData(languageList);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const fetchThemeList = async () => {
        try {
            const url = config.cmsConfig.apiEndpoint.themeList;
            const result: ICommonReturnType = await getService(url);
            const themeList = result?.data.map((theme: IThemeListProps) => {
                return {
                    name: theme.name,
                    id: theme.id
                }
            })
            setThemeData(themeList);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const convertGameId = (gameobj: any) => {
        if (gameobj && gameobj !== "undefined") {
            const parseGame = JSON.parse(gameobj);
            const editedGameName = parseGame.GameName;
            return editedGameName;
        }
    }

    const editRecordInitialState = {
        title: editRecordData.title as string || "",
        link: editRecordData.link as string || "",
        sort: editRecordData.sort || 0,
        eventId: editRecordData.eventId as string || "",
        gameId: convertGameId(editRecordData.gameId),
        icon: editRecordData.icon as string || "",
        themeId: editRecordData.themeId || 0,
        langId: editRecordData.langId || 0,
        isMobile: !!editRecordData.isMobile,
        displayFor: displayOptions.filter((value) => value.name === editRecordData.displayFor)[0]?.id || -1,
        isActive: !!editRecordData.isActive
    }

    const initialValues = !!id ? editRecordInitialState : {
        title: "",
        link: "",
        sort: 0,
        eventId: "",
        gameId: "",
        icon: "",
        themeId: "",
        langId: "",
        isMobile: false,
        displayFor: -1,
        isActive: false
    }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, dirty, resetForm, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: createNavigationSchema,
        onSubmit: (values) => {
            const displayForArr = displayOptions.filter((value) => value.id === Number(values.displayFor));
            const formData = new FormData();
            if (values.gameId) {
                const removeIndexOfgame = (values.gameId as string).split("-")[0];
                const filteredGame = gamesData.filter((game: IGameData) => game.GameName === removeIndexOfgame)[0];
                formData.append('gameId', JSON.stringify(filteredGame));
            } else {
                formData.append('gameId', "undefined");
            }
            formData.append('title', values.title);
            formData.append('link', values.link);
            formData.append('sort', values.sort as any);
            formData.append('eventId', values.eventId as any);
            formData.append('icon', values.icon);
            formData.append('themeId', Number(values.themeId) as any);
            formData.append('langId', Number(values.langId) as any);
            formData.append('isMobile', values.isMobile.toString());
            formData.append('displayFor', displayForArr[0].name);
            formData.append('isActive', values.isActive.toString());
            mutationMint.mutateAsync(formData);
        },
    });

    const mutationMint = useMutation({
        mutationFn: async (param: FormData) => {
            const url = !!id ? `${config.cmsConfig.apiEndpoint.updateNavigation}/${id}` : config.cmsConfig.apiEndpoint.createNavigation;
            try {
                const result = !!id ? await putService(url, param) : await postFormDataService(url, param);
                return result;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: (data) => {
            refetch();
            closeModal(modalId);
            toast.success(data.message);
            resetForm();
        },
        onError: (error: any) => {
            toast.error('Failed to create ' + error.response?.data?.message);
        },
    });

    const handleIconUpload = (file: File | null) => {
        setFieldValue('icon', file);
    }

    const filterOptions = (options: any, state: any) => {
        const { inputValue } = state;
        const filteredOptions = options.filter((option: any) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
        return filteredOptions.sort();
    };

    const handleAutocompleteChange = (event: any, value: any) => {
        setFieldValue('gameId', value ? value.label : null);
    };

    const cleanLabel = (label: string | any) => {
        return !!label && label.split('-')[0];
    };

    return (isLoading ? <Stack minHeight={300}>{<LoadingSpinner small={true} />}</Stack> :
        <div className="model-inner-margintop">
            <form onSubmit={handleSubmit}>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.title} name="title" id={"title"} label={"Title"} variant="outlined" errorMessage={errors.title} isTouched={touched.title} />
                    </Stack>
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.sort} name="sort" id={"sort"} label={"Sort"} variant="outlined" errorMessage={errors.sort} isTouched={touched.sort} />
                    </Stack>
                </Stack>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.link} name="link" id={"link"} label={"Link"} variant="outlined" errorMessage={errors.link} isTouched={touched.link} />
                    </Stack>
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.eventId} name="eventId" id={"eventId"} label={"Event ID"} variant="outlined" errorMessage={errors.eventId} isTouched={touched.eventId} />
                    </Stack>
                </Stack>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={gameOptions}
                            filterOptions={filterOptions}
                            value={cleanLabel(values.gameId) || ""}
                            onChange={handleAutocompleteChange}
                            isOptionEqualToValue={(option) => cleanLabel(option.label)}
                            renderOption={(props, option: IGameOptions) => {
                                return (
                                    <li {...props} key={option.id}>
                                        {cleanLabel(option.label)}
                                    </li>
                                )
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Game" />}
                        />
                    </Stack>
                    <Stack flex={1}>
                        <SelectComponent
                            value={values.themeId}
                            options={themeData}
                            size="medium"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="themeId"
                            name="themeId"
                            label="Theme"
                            labelId="themeId"
                            errorMessage={errors.themeId}
                            touched={touched.themeId}
                        />
                    </Stack>
                </Stack>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <SelectComponent
                            value={values.langId}
                            options={languageData}
                            size="medium"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="langId"
                            name="langId"
                            label="Language"
                            labelId="langId"
                            errorMessage={errors.langId}
                            touched={touched.langId}
                        />
                    </Stack>
                    <Stack flex={1}>
                        <SelectComponent
                            value={values.displayFor}
                            options={displayOptions}
                            size="medium"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="displayFor"
                            name="displayFor"
                            label="Display for"
                            labelId="displayFor"
                            errorMessage={errors.displayFor}
                            touched={touched.displayFor}
                        />
                    </Stack>
                </Stack>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <UploadButton onChange={handleIconUpload} label={"Select icon"} imgName={!!id ? editRecordData.icon : null} imgUrl={!!id ? editRecordData.iconUrl : null} />
                    </Stack>

                    <Stack flex={1} marginBottom={2} flexDirection={"row"} gap={2}>
                        <FormControlLabel control={
                                   <Checkbox
                                checked={values.isMobile}
                                onChange={handleChange}
                                name="isMobile"
                              
                            />}
                            sx={{
                                "& .MuiCheckbox-root":{
                                    color:"#5688F7"
                                }
                            }}
                            label="Mobile" />
                        <FormControlLabel control={
                               <Checkbox
                                checked={values.isActive}
                                onChange={handleChange}
                                name="isActive"
                            />}
                            sx={{
                                "& .MuiCheckbox-root":{
                                    color:"#5688F7",
                                }
                            }}
                            label="Active" />
                    </Stack>
                </Stack>

                <div className={`button_Wrapper`}>
                    <Button className="customBtn" disabled={!dirty} type="reset" onClick={() => resetForm()} size="small" variant="outlined">
                        {"Reset"}
                    </Button>
                    <Button className="customBtn" disabled={!isValid || !dirty} type="submit" size="small" variant="outlined">
                        {"submit"}</Button>
                </div>
            </form>
        </div >
    )
}