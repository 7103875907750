import { useEffect, useState } from "react"
import { ICommonReturnType, INavigationListProps } from "../../interfaces/interfaceCommon";
import config from "../../constants/config";
import getService from "../../services/getService";
import { GridAlignment, GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../common/UI/table/dataTable";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import { useModal } from "../../store/modalContext";
import { ActionButtons } from "../common/actionButtons/ActionButtons";
import { DeleteModal } from "../common/actionButtons/DeleteModal";
import { AddNavigation } from "./AddNavigation";
import { useModulePrivilage } from "../../store/modulePrivilageContext";

export const Navigation = () => {
    const [navigationData, setNavigationData] = useState<INavigationListProps[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { openModal, closeModal, modalId } = useModal();
    const { canAdd } = useModulePrivilage();
    useEffect(() => {
        fetchNavigationList();
    }, []);

    const fetchNavigationList = async () => {
        setIsDataLoading(true);
        try {
            const url = config.cmsConfig.apiEndpoint.navigationList;
            const result: ICommonReturnType = await getService(url);
            setNavigationData(result.data);
        } catch (error: any) {
            console.log(error.message);
        } finally {
            setIsDataLoading(false);
        }
    };


    const createColumns = () => {
        const columns: GridColDef[] = [
            {
                field: 'id', headerName: 'ID', resizable: false, headerAlign: "center", align: "center", flex: 0.75
            },
            {
                field: 'sort', headerName: 'Sort', resizable: false, headerAlign: "center", align: "center", flex: 0.50
            },
            {
                field: 'title', headerName: 'Title',    resizable: false, flex: 1, headerAlign: "center", align: "center"
            },
            {
                field: 'link', headerName: 'Link',    resizable: false, flex: 1, headerAlign: "center", align: "center"
            },
            {
                field: 'sort', headerName: 'Sort',    resizable: false, flex: 1, headerAlign: "center", align: "center"
            },
            {
                field: 'isMobile', headerName: 'Mobile',    resizable: false, flex: 1, headerAlign: "center", align: "center"
            },
            {
                field: 'eventId', headerName: 'Event ID',    resizable: false, flex: 1, headerAlign: "center", align: "center"
            },
            {
                field: 'isActive', headerName: 'Active',    resizable: false, flex: 1, headerAlign: "center", align: "center"
            },
            {
                field: 'Actions', headerName: 'Actions',    resizable: false, flex: 1, headerAlign: "center" as GridAlignment,
                align: "center" as GridAlignment, renderCell: (params: any) => {
                    return (
                        <ActionButtons name={params.row.title}
                            deleteModal={<DeleteModal closeModal={closeModal} modalId={modalId} name={params.row.title} refetch={fetchNavigationList} deleteUrl={`${config.cmsConfig.apiEndpoint.deleteNavigation}/${params.row.id}`} />}
                            editModal={<AddNavigation closeModal={closeModal} modalId={modalId} refetch={fetchNavigationList} id={params.row.id} />
                            }
                            desktopImage={<img src={`${params.row.iconUrl}`} alt="dektop" className="desktopImage" />}
                            shouldShowDesktopIcon={!!params.row.icon}
                        />
                    )
                },
            },
        ];
        return columns;
    };

    const handleActionButtonClick = () => {
        const modalContent = <AddNavigation closeModal={closeModal} modalId={modalId} refetch={fetchNavigationList} />;
        openModal(modalContent, "Add Navigation")
    }

    return (
        <div>
            {isDataLoading ? <LoadingSpinner /> :
                <DataTable rowData={navigationData}
                    columns={createColumns()}
                    rowsPerPageOptions={[100, 25]}
                    shouldShowDatePicker={false}
                    shouldShowSearch={true}
                    shouldShowActionButton={canAdd ? true : false}
                    onClickActionButton={handleActionButtonClick}
                />
            }
        </div>
    )
}