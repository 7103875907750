import { useEffect, useState } from "react"
import { IThemeListProps, ICommonReturnType } from "../../interfaces/interfaceCommon";
import config from "../../constants/config";
import getService from "../../services/getService";
import { GridAlignment, GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../common/UI/table/dataTable";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import { useModal } from "../../store/modalContext";
import { ActionButtons } from "../common/actionButtons/ActionButtons";
import { AddBranding } from "./addEditBranding";
import { DeleteModal } from "../common/actionButtons/DeleteModal";
import { useModulePrivilage } from "../../store/modulePrivilageContext";

export const Branding = () => {
    const [rowData, setRowData] = useState<IThemeListProps[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { openModal, closeModal, modalId } = useModal();
    const { canAdd, canEdit, canDelete } = useModulePrivilage();
    useEffect(() => {
        fetchBrandingData();
    }, []);

    const fetchBrandingData = async () => {
        setIsDataLoading(true);
        try {
            const url = config.cmsConfig.apiEndpoint.brandingList;
            const response: ICommonReturnType = await getService(url);
            setRowData(response.data);
        } catch (error) {
            console.log("failed to load branding data");
        } finally {
            setIsDataLoading(false);
        }
    }

    const createColumns = () => {
        const columns: GridColDef[] = [
            {
                field: 'id', headerName: 'ID', resizable: false, headerAlign: "center",
                align: "center"
            },
            {
                field: 'title', headerName: 'Name',    resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },

      {
        field: "attributeKey",
        headerName: "Key",
        resizable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "attributeValue",
        headerName: "Value",
        resizable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const isGradient =
            params.row.attributeValue.includes("linear-gradient");
          if (isGradient) {
            return params.row.attributeValue;
          }
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "70%",
                  gap:4
                }}
              >
                <input
                  type="color"
                  value={params.row.attributeValue}
                  disabled={true}
                  style={{ width: "60px", marginTop: "4px", border: 0 ,borderRadius:"0rem"}}
                />
                <div style={{ color: "var(--text-color)" }}>
                  {params.row.attributeValue}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        field: "displayFor",
        headerName: "Display for",
        resizable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "isMobile",
        headerName: "Mobile",
        resizable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "isActive",
        headerName: "Active",
        resizable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      ...(canEdit || canDelete
        ? [
            {
              field: "Actions",
              headerName: "Actions",
              resizable: false,
              flex: 1,
              headerAlign: "center" as GridAlignment,
              align: "center" as GridAlignment,
              renderCell: (params: any) => {
                return (
                  <ActionButtons
                    name={params.row.title}
                    deleteModal={
                      <DeleteModal
                        closeModal={closeModal}
                        modalId={modalId}
                        name={params.row.title}
                        refetch={fetchBrandingData}
                        deleteUrl={`${config.cmsConfig.apiEndpoint.deleteBrand}/${params.row.id}`}
                      />
                    }
                    editModal={
                      <AddBranding
                        closeModal={closeModal}
                        modalId={modalId}
                        refetch={fetchBrandingData}
                        id={params.row.id}
                      />
                    }
                  />
                );
              },
            },
          ]
        : []),
    ];
    return columns;
  };

    const handleActionButtonClick = () => {
        const modalContent = <AddBranding closeModal={closeModal} modalId={modalId} refetch={fetchBrandingData} />;
        openModal(modalContent, "Add Branding")
    }

    return (
        <div>
            {isDataLoading ? <LoadingSpinner /> :
                <DataTable rowData={rowData}
                    columns={createColumns()}
                    rowsPerPageOptions={[100, 25]}
                    shouldShowDatePicker={false}
                    shouldShowSearch={true}
                    shouldShowActionButton={canAdd ? true : false}
                    onClickActionButton={handleActionButtonClick}
                />
            }
        </div>
    )
}