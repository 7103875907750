import { useEffect, useState } from "react"
import { ICommonReturnType, ICategoryTypeListProps } from "../../interfaces/interfaceCommon";
import config from "../../constants/config";
import getService from "../../services/getService";
import { GridAlignment, GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../common/UI/table/dataTable";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import { useModal } from "../../store/modalContext";
import { AddEditCategoryType } from "./AddEditCategoryType";
import { ActionButtons } from "../common/actionButtons/ActionButtons";
import { DeleteModal } from "../common/actionButtons/DeleteModal";
import TimezoneCellRenderer from "../common/timezone/TimezoneCellRenderer";
import { useModulePrivilage } from "../../store/modulePrivilageContext";

export const CategoryType = () => {
    const [rowData, setRowData] = useState<ICategoryTypeListProps[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { openModal, closeModal, modalId } = useModal();
    const { canAdd, canEdit, canDelete } = useModulePrivilage();
    useEffect(() => {
        fetchCategoryTypeData();
    }, []);

    const fetchCategoryTypeData = async () => {
        setIsDataLoading(true);
        try {
            const url = config.cmsConfig.apiEndpoint.categoryTypeList;
            const response: ICommonReturnType = await getService(url);
            setRowData(response.data);
        } catch (error) {
            console.log("failed to load category type data")
        } finally {
            setIsDataLoading(false);
        }
    }

    const createColumns = () => {
        const columns: GridColDef[] = [
            {
                field: 'id', headerName: 'ID', resizable: false, headerAlign: "center",
                align: "center"
            },
            {
                field: 'name', headerName: 'Name',    resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'isActive', headerName: 'Status',    resizable: false, flex: 1, headerAlign: "center",
                align: "center",
                renderCell: (params) => {
                    return (
                        <span>{!!params.row.isActive ? "Active" : "In-Active"}</span>
                    );
                },
            },
            {
                field: 'createdAt', headerName: 'Created at',    resizable: false, flex: 1, headerAlign: "center",
                align: "center",
                renderCell: (params) => <TimezoneCellRenderer {...params} />,
                // valueFormatter: (params) => formatDate(params)
            },
            {
                field: 'updatedAt', headerName: 'Updated at',    resizable: false, flex: 1, headerAlign: "center",
                align: "center",
                renderCell: (params) => <TimezoneCellRenderer {...params} />,
                // valueFormatter: (params) => formatDate(params)
            },
            ...((canEdit || canDelete) ? [
                {
                    field: 'Actions', headerName: 'Actions',    resizable: false, flex: 1, headerAlign: "center" as GridAlignment,
                    align: "center" as GridAlignment, renderCell: (params: any) => {
                        return (
                            <ActionButtons
                                name={params.row.name}
                                deleteModal={<DeleteModal closeModal={closeModal} modalId={modalId} name={params.row.name} refetch={fetchCategoryTypeData} deleteUrl={`${config.cmsConfig.apiEndpoint.deleteCategoryType}/${params.row.id}`} />}
                                editModal={<AddEditCategoryType closeModal={closeModal} modalId={modalId} refetch={fetchCategoryTypeData} id={params.row.id} />}
                            />
                        )
                    },
                },
            ] : []),
        ];
        return columns
    };

    const handleActionButtonClick = () => {
        const modalContent = <AddEditCategoryType closeModal={closeModal} modalId={modalId} refetch={fetchCategoryTypeData} />;
        openModal(modalContent, "Create Category Type")
    }

    return (
        <div>
            {isDataLoading ? <LoadingSpinner /> :
                <DataTable rowData={rowData}
                    columns={createColumns()}
                    rowsPerPageOptions={[100, 25]}
                    shouldShowDatePicker={false}
                    shouldShowSearch={true}
                    shouldShowActionButton={canAdd ? true : false}
                    onClickActionButton={handleActionButtonClick}
                />
            }
        </div>
    )
}