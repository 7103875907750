import React, { useContext } from "react";
import ThemeContext from "../../../../../store/themeContext";
import classes from "./ThemeBox.module.scss";
import { Tooltip } from "@mui/material";
// import lightIcon from "../../../../../assets/images/"; // Path to light mode icon
// import darkIcon from "../../../../../assets/icons/dark-mode-icon.svg"; // Path to dark mode icon
import LanguageIcon from "@mui/icons-material/Language";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LightModeSharpIcon from "@mui/icons-material/LightModeSharp";
function ThemeBox() {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;

  return (
    <Tooltip title="Set Theme">
      <div onClick={() => themeCtx.toggleTheme()} style={{display:"flex",margin:"5px "}}>
        {/* <img
          src={theme === "dark" ? darkIcon : lightIcon}
          alt="Toggle Theme"
          className={classes.toggleIcon}
        /> */}
        {theme === "light" ? (
          <LightModeOutlinedIcon className={classes.toggleIcon}  style={{cursor:"pointer"}}/>
        ) : (
          <LightModeSharpIcon className={classes.toggleIcon}  style={{cursor:"pointer"}}/>
        )}
        
        {/* <LanguageIcon color={theme === "dark" ? "success" : "warning"} /> */}
      </div>
    </Tooltip>
  );
}

export default ThemeBox;
