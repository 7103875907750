import {
  MenuItem,
  FormControl,
  ClickAwayListener,
} from "@mui/material";
import { useTimezone } from "../../../store/timezoneContext";
import LanguageIcon from "@mui/icons-material/Language";
import { useState } from "react";

const timezones = [
  "UTC", // Current Local Time
  "Asia/Dubai", // Gulf Standard Time (UTC+4)
  "Asia/Kolkata", // India Standard Time (UTC+5:30)
];

function Timezone() {
  const { timezone, setTimezone } = useTimezone();
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const handleTimezoneSelect = (selectedTimezone: string) => {
    setTimezone(selectedTimezone);
    setDropDownOpen(false); // Close the dropdown after selection
  };

  const handleClickAway = () => {
    setDropDownOpen(false); // Close the dropdown when clicking outside
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl
        size={"small"}
        sx={{ m: 1, position: "relative", alignItems: "center" }}
      >
        {/* Language Icon as the trigger */}
        <LanguageIcon
          sx={{ cursor: "pointer" }}
          onClick={() => setDropDownOpen((prev) => !prev)}
        />

        {/* Custom dropdown */}
        {dropDownOpen && (
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "6px",
              width: "auto",
              position: "absolute",
              top: 30,
              zIndex: 10,
            }}
          >
            {timezones.map((tz) => (
              <MenuItem
                key={tz}
                value={tz}
                onClick={() => handleTimezoneSelect(tz)}
                sx={{
                  backgroundColor: timezone === tz ? "#f0f0f0" : "transparent", // Highlight selected
                  "&:hover": {
                    backgroundColor: "#e0e0e0", // Add hover effect
                  },
                }}
              >
                {tz}
              </MenuItem>
            ))}
          </div>
        )}
      </FormControl>
    </ClickAwayListener>
  );
}

export default Timezone;
