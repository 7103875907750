import { TextField } from "@mui/material";
import { IInputBoxProps } from "../../../../interfaces/inputBox";
import { FormError } from "../../FormError/FormError";
import { shouldShowError } from "../../../../utils/utils";

export const InputBox: React.FunctionComponent<IInputBoxProps> = ({ onChangeHandler, onBlurHandler, value, label, variant, name, id, errorMessage, isTouched, startAdornment, type, disabled }) => {
    return (
        <div>
            <div>{label}</div>
            <TextField
                onChange={onChangeHandler}
                onBlur={onBlurHandler ? onBlurHandler : () => { }}
                value={value}
                name={name}
                fullWidth
                id={id}
                // label={label}
                variant={variant}
                InputProps={{
                    startAdornment: startAdornment ? startAdornment : null,
                }}
                placeholder={label}
                type={type || "text"}
                className="inputBox"
                disabled={disabled}
                autoComplete="off"
                sx={{
                    width:"280px",
                    "& .MuiOutlinedInput-input":{
                        padding:"9.5px 12px",
                    },
                    "& .MuiInputLabel-root":{
                        top:"-5.5px"
                    }
                }}
            />
            {errorMessage && <div>
                {shouldShowError(errorMessage, isTouched) && <FormError error={errorMessage} />}
            </div>}
        </div>
    )
}