import {
  DataGrid,
  gridClasses,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
} from "@mui/x-data-grid";
import { IDataTableProps } from "../../../../interfaces/interfaceCommon";
import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import classes from "./dataTable.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import { NoRecords } from "./noRecords";
import { isNonEmptyArr } from "../../../../utils/utils";
import PaginationItem from "@mui/material/PaginationItem";
// import { images } from "../../../../constants";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

export const DataTable: React.FunctionComponent<IDataTableProps> = ({
  rowData,
  columns,
  rowsPerPageOptions,
  shouldShowDatePicker,
  shouldShowSearch,
  onPageChange,
  shouldShowActionButton,
  onClickActionButton,
  filterKey,
  filterOptions = [],
  checkboxSelection = false,
  actioButtonText,
}) => {
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [search, setSearch] = useState("");
  const [backup, setBackup] = useState(rowData || []);
  const [filteredRows, setFilteredRows] = useState(rowData || []);
  const [selectedCategory, setSelectedCategory] = useState<string | "">("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  // Initialize backup data when rowData changes
  useEffect(() => {
    setBackup(rowData);
    setFilteredRows(rowData);
  }, [rowData]);

  // Filter Logic
  const applyFilters = () => {
    const filteredData = backup?.filter((row) => {
      const createdAtDate = dayjs(row.createdAt);

      // Date range filter
      const isAfterFromDate =
        !fromDate ||
        createdAtDate.isAfter(fromDate, "day") ||
        createdAtDate.isSame(fromDate, "day");
      const isBeforeToDate =
        !toDate ||
        createdAtDate.isBefore(toDate, "day") ||
        createdAtDate.isSame(toDate, "day");

      // Category filter
      const matchesCategory =
        selectedCategory === "" ||
        (filterKey === "Category"
          ? row[filterKey]?.id === selectedCategory
          : row[filterKey as string] === selectedCategory);

      // Search filter
      const matchesSearch =
        search.trim() === "" ||
        Object.values(row).some((field) =>
          String(field).toLowerCase().includes(search.toLowerCase())
        );

      // Combine all filters
      return (
        isAfterFromDate && isBeforeToDate && matchesCategory && matchesSearch
      );
    });

    setFilteredRows(filteredData || []);
  };

  // Handle search input
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  // Handle category selection
  const handleCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedCategory(event.target.value as string);
  };

  // Handle date changes
  const handleFromDateChange = (date: Dayjs | null) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setToDate(date);
  };

  // Reapply filters whenever any filter value changes
  useEffect(() => {
    applyFilters();
  }, [fromDate, toDate, search, selectedCategory]);

  const handlePageSizeChange = (event: any) => {
    const newPageSize = event.target.value as number;
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: newPageSize,
    }));
  };
  // const filteredRows = isNonEmptyArr(rowData)
  //   ? rowData.filter((row) => {
  //       const createdAtDate = dayjs(row.createdAt);

  //       // Filter by date range
  //       const isAfterFromDate =
  //         !fromDate ||
  //         createdAtDate.isAfter(fromDate, "day") ||
  //         createdAtDate.isSame(fromDate, "day");
  //       const isBeforeToDate =
  //         !toDate ||
  //         createdAtDate.isBefore(toDate, "day") ||
  //         createdAtDate.isSame(toDate, "day");

  //       // Filter by search query
  //       const lowerCaseSearchQuery = search.toLowerCase();
  //       const isNumericSearch =
  //         !isNaN(lowerCaseSearchQuery as any) && lowerCaseSearchQuery !== "";
  //       let matchesSearch = false;

  //       // Iterate through all properties of the row object and check if any value matches the search query
  //       Object.values(row).forEach((value) => {
  //         const stringValue = String(value).toLowerCase();
  //         if (
  //           typeof value === "string" &&
  //           stringValue.includes(lowerCaseSearchQuery)
  //         ) {
  //           matchesSearch = true;
  //         } else if (isNumericSearch) {
  //           // Check if the value is numeric and matches the search query
  //           const numericValue = parseFloat(lowerCaseSearchQuery);
  //           if (!isNaN(numericValue) && numericValue === value) {
  //             matchesSearch = true;
  //           }
  //         }
  //       });

  //       // Filter by category
  //       const matchesCategory =
  //         selectedCategory == "" ||
  //         (filterKey === "Category"
  //           ? row[filterKey]?.id === selectedCategory
  //           : row[filterKey as string] === selectedCategory);

  //       if (shouldShowSearch && shouldShowDatePicker) {
  //         return (
  //           isAfterFromDate &&
  //           isBeforeToDate &&
  //           matchesSearch &&
  //           matchesCategory
  //         );
  //       } else if (shouldShowSearch) {
  //         return matchesSearch && matchesCategory;
  //       } else if (shouldShowDatePicker) {
  //         return isAfterFromDate && isBeforeToDate && matchesCategory;
  //       } else {
  //         return matchesCategory;
  //       }
  //     })
  //   : [];

  const CustomNoRowsOverlay = () => {
    return <NoRecords />;
  };

  const showActionButton = () => {
    return shouldShowActionButton;
  };

  // const calculateMaxWidth = () => {
  //   if (shouldShowDatePicker && shouldShowActionButton) {
  //     return "40%";
  //   } else if (shouldShowDatePicker || shouldShowActionButton) {
  //     return "60%";
  //   }
  //   return "100%";
  // };

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        variant="outlined"
        // shape="rounded"
        siblingCount={2} // Remove sibling pages
        boundaryCount={0}
        page={page + 1}
        count={pageCount}
        sx={{
          borderRadius: "9px",
          overflow: "hidden",
          "& .MuiPaginationItem-ellipsis": {
            display: "none",
          },
        }}
        renderItem={(props2) => (
          // @ts-expect-error
          <PaginationItem
            sx={{
              borderRadius: 0,
              border: 0,
              margin: "0.6px",
              paddingY: "19px",
              paddingX: "16px",
              backgroundColor: "var(--rowColor)",
              fontSize: "10px",
              fontWeight: "600",
              "&:hover": {
                backgroundColor: "var(--rowColor)",
              },
              "& .css-vix6ct.Mui-selected": {
                color: "var(--text-color)",
              },
            }}
            {...props2}
            disableRipple
          />
        )}
        onChange={(event: React.ChangeEvent<unknown>, value: number) =>
          apiRef.current.setPage(value - 1)
        }
      />
    );
  }
  const rowHeight = 36;
  const columnHeaderHeight = 45;
  const CustomSortIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="var(--text-color)"
      width="15"
      height="15"
      style={{
        position:"relative",
        left:"25px"
      }}
    >
      <path d="M12 4l-6 6h12l-6-6zm0 16l6-6H6l6 6z" />
    </svg>
  );

  const updatedColumns = columns.map((column) => ({
    ...column,
    headerClassName: "super-app-theme--header",
  }));

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div className={classes.topFilterWrapper}>
        {shouldShowDatePicker && (
          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={2}
            maxWidth={"40%"}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select from date"
                value={fromDate}
                onChange={handleFromDateChange}
                disabled={!rowData.length}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select to date"
                value={toDate}
                onChange={handleToDateChange}
                disabled={!rowData.length}
              />
            </LocalizationProvider>
          </Stack>
        )}
        <FormControl
          sx={{
            flexDirection: { sm: "column", md: "row" },
            justifyItems: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Select
            value={paginationModel.pageSize}
            onChange={handlePageSizeChange}
            displayEmpty
            sx={{
              backgroundColor: "var(--bgc2)",
              border: "0",
              borderRadius: "5px",
              fontSize: "16px",
              outline: "none",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiOutlinedInput-input": {
                padding: "8px 10px",
              },
              color: "var(--text-color)",
            }}
            style={{ padding: 0 }}
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <span>Entries per page</span>
        </FormControl>
        <Stack direction="row" gap={1}>
          {shouldShowSearch && (
            <Stack
              sx={{
                display: "flex",
                gap: "8px",
                flexDirection: "row",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  position: "relative",
                  backgroundColor: "var(--bgc2)",
                  borderRadius: 1.5,
                  overflow: "hidden",
                }}
              >
                <input
                  onChange={handleSearch}
                  value={search}
                  name={search}
                  id="search"
                  style={{
                    border: 0,
                    outline: "none",
                    padding: "0.5rem 10px",
                    overflow: "hidden",
                    width: "15rem",
                    backgroundColor: "var(--bgc2)",
                  }}
                  placeholder={"Search"}
                  type={"text"}
                  disabled={!rowData?.length}
                  autoComplete="off"
                />
                <SearchIcon
                  fontWeight={"400"}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 2,
                    fontWeight: "300",
                    color: "gray",
                  }}
                />
              </Stack>
              {filterKey && isNonEmptyArr(filterOptions) && (
                <Stack sx={{}}>
                  <FormControl>
                    <Select
                      labelId="category-select-label"
                      value={selectedCategory}
                      onChange={(e) => handleCategoryChange(e as any)}
                      displayEmpty
                      sx={{
                        backgroundColor: "var(--bgc2)",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "7px 10px",
                        },
                        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            paddingRight: "145px",
                          },
                      }}
                    >
                      <MenuItem value="">All</MenuItem>
                      {filterOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </Stack>
          )}

          {showActionButton() && (
            <Stack className={classes.actionButton}>
              {actioButtonText ? (
                <Button
                  size="large"
                  disableRipple
                  variant="text"
                  onClick={onClickActionButton}
                  sx={{
                    color: "#fff",
                    background: "var(--primaryColor)",
                    padding: "5px 8px",
                  }}
                >
                  {actioButtonText}
                </Button>
              ) : (
                <Button
                  // size="large"
                  disableRipple
                  variant="text"
                  onClick={onClickActionButton}
                  style={{ background: "transparent" }}
                  startIcon={
                    <AddCircleRoundedIcon
                      sx={{
                        color: "#5688F7",
                        width: "1.7rem",
                        height: "1.7rem",
                      }}
                    />
                  }
                >
                  {
                    // <img src={images.plusBtn} alt="Icon" style={{ width: "2rem", height: "2rem" }} />
                  }
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </div>

      <DataGrid
        sx={{
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: "var(--toolbar-bg-color)",
            color: "var(--text-color)",
            fontSize: "0.875rem",
            fontWeight: 500,
            marginBottom: 1,
          },
          "& .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer":
            {
              justifyContent: "",
            },
          "& .MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
            backgroundColor: "var(--rowColor)",
            padding: "3.5px 9px",
            color: "var(--text-color)",
          },
          "& .MuiSvgIcon-root": {
            fill: "var(--text-color)",
          },

          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: "none",
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: "none",
            },
          "& .MuiDataGrid-cell": {
            fontSize: "0.630rem",
            padding: "0 5px",
          },
          "& .super-app-theme--header": {
            backgroundColor: "var(--rowColor)",
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            maxHeight: "calc(100dvh - 315px) !important",
            overflow: "auto",
          },
          [`& .MuiDataGrid-columnHeaders`]: {
            fontSize: "0.700rem",
          },
          "& .super-app-theme--even": {
            backgroundColor: "var(--rowColor)",
            borderColor: "transparent",
            outline: "none",
            borderRadius: "3px",
            "&:hover": {
              backgroundColor: "var(--rowColor)",
            },
          },
          "& .super-app-theme--odd": {
            backgroundColor: "var(--rowColor2)",
            borderColor: "transparent",
            outline: "none",
            "&:hover": {
              backgroundColor: "var(--rowColor2)",
            },
          },
          "& .MuiDataGrid-root": {
            rowBorderColor: 0,
          },
        }}
        style={{
          borderColor: "transparent",
          backgroundColor: "var(--rowColor2)",
          padding: 6,
        }}
        rows={filteredRows}
        columns={updatedColumns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 !== 0
            ? `super-app-theme--even`
            : `super-app-theme--odd`
        }
        disableAutosize
        sortingOrder={["desc", "asc"]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={rowsPerPageOptions}
        checkboxSelection={checkboxSelection}
        getRowId={(rowData) => rowData.id}
        rowHeight={rowHeight}
        columnHeaderHeight={columnHeaderHeight}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: CustomPagination,
          toolbar: GridToolbar,
          columnHeaderSortIcon: CustomSortIcon, // Replace the default sort icon
        }}
        // disableColumnSorting={false}
        disableRowSelectionOnClick={true}
        disableColumnMenu={true}
        className="dataTableMain"
      />
    </div>
  );
};
