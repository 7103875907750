import { IconButton } from "@mui/material";
import { IActionsButtonsProps } from "../../../interfaces/interfaceCommon";
import { useModal } from "../../../store/modalContext";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useModulePrivilage } from "../../../store/modulePrivilageContext";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import Tooltip from "@mui/material/Tooltip";
import StayCurrentPortraitOutlinedIcon from "@mui/icons-material/StayCurrentPortraitOutlined";
export const ActionButtons: React.FunctionComponent<IActionsButtonsProps> = ({
  name,
  deleteModal,
  editModal,
  isFullwidth,
  desktopImage,
  mobileImage,
  shouldShowDesktopIcon,
  shouldShowMobileIcon,
  shouldShowDelete = true,
}) => {
  const { openModal } = useModal();
  const { canEdit, canDelete } = useModulePrivilage();
  const handleDelete = () => {
    const modalContent = deleteModal;
    openModal(modalContent, `Delete ${name}?`);
  };
  const handleEdit = () => {
    const modalContent = editModal;
    openModal(modalContent, `Edit ${name}`, isFullwidth);
  };

  const handleMobileImage = () => {
    const modalContent = mobileImage;
    openModal(modalContent);
  };

  const handleDesktopImage = () => {
    const modalContent = desktopImage;
    openModal(modalContent);
  };

  return (
    <div className={"buttonsWrapper"}>
      {shouldShowDesktopIcon && (
        <Tooltip title="Desktop image" placement="top">
          <IconButton
            size="small"
            color="primary"
            aria-label="Desktop image"
            onClick={handleDesktopImage}
          >
            <DesktopWindowsOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {shouldShowMobileIcon && (
        <Tooltip title="Mobile image" placement="top">
          <IconButton
            size="small"
            color="primary"
            aria-label="Mobile image"
            onClick={handleMobileImage}
          >
            <StayCurrentPortraitOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {canEdit && (
        <Tooltip title="edit" placement="top">
          <IconButton
            size="small"
            color="primary"
            aria-label="edit record"
            onClick={handleEdit}
          >
            {/* <EditOutlinedIcon fontSize="small" /> */}
            <img
              style={{width:18}}
              src={require("../../../assets/images/edit_icon.svg").default}
              alt="edit icon"
            />
          </IconButton>
        </Tooltip>
      )}
      {canDelete && shouldShowDelete && (
        <Tooltip title="delete" placement="top">
          <IconButton
            size="small"
            color="primary"
            aria-label="delete record"
            onClick={handleDelete}
          >
            {/* <DeleteIcon fontSize="small" color={"warning"} /> */}
            <img
              style={{width:18}}
              src={require("../../../assets/images/delete_icon.svg").default}
              alt="delete icon"
            />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
