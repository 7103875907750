import { Tooltip } from "@mui/material";
// import FitScreenIcon from '@mui/icons-material/FitScreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
function FullScreen() {
  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) { // Firefox
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) { // Chrome, Safari and Opera
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) { // IE/Edge
        (document as any).msExitFullscreen();
      }
    } else {
      // Enter fullscreen
      const element = document.documentElement as HTMLElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if ((element as any).mozRequestFullScreen) { // Firefox
        (element as any).mozRequestFullScreen();
      } else if ((element as any).webkitRequestFullscreen) { // Chrome, Safari and Opera
        (element as any).webkitRequestFullscreen();
      } else if ((element as any).msRequestFullscreen) { // IE/Edge
        (element as any).msRequestFullscreen();
      }
    }
  };
  

  return (
    <Tooltip title="Full Screen">
      <div onClick={toggleFullScreen} style={{display:"flex",alignItems:"center",margin:"8px 3px 0px 7px",cursor:"pointer"}}>
        <div>
          <OpenInFullIcon  />
        </div>
      </div>
    </Tooltip>
  );
}

export default FullScreen;