import { useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { ArrowDownwardRounded, Logout, Settings } from "@mui/icons-material";
import classes from "./ProfileMenu.module.scss";
import { images } from "../../../../../constants";
import { useModal } from "../../../../../store/modalContext";
import config from "../../../../../constants/config";
import { removeLocalStorageData } from "../../../../../utils/utils";
import postService from "../../../../../services/post";
// import ProfilePage from "../../../../whitelabel/profilePage/ProfilePage";
function ProfileMenu() {
  const { openModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: any, tab: any) => {
    handleClose();
    switch (tab) {
      case "ChangePassword":
        const modalContent = "";
        openModal(modalContent, "Change Password");
        break;
      // case 'Profile': navigate(config.roleBasedConfig['whitelabel'].routesConstatnts.routeUserProfile);
      //   // const modalContent1 = <ProfilePage/>
      //   // openModal(modalContent1, 'Profile');
      //   break;
      case "Logout":
        logOut();
        break;
    }
  };

  const logOut = async () => {
    try {
      const url = `${process.env.REACT_APP_CMS}${config.cmsConfig.apiEndpoint.logoutApiUrl}`;
      const result = await postService(url, {});
      return result;
    } catch (error) {
      throw error;
    } finally {
      removeLocalStorageData();
      window.location.href = "/login";
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <div style={{}}>
            <IconButton
              onClick={handleMenu}
              size="small"
              sx={{
                ml: 1,
                width: "72px",
                backgroundColor: "var(--rowColor)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "22px",
              }}
              aria-controls={Boolean(anchorEl) ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            >
              <img
                src={images.avt}
                alt="avatar"
                style={{ width: 30, height: 30, borderRadius: "50%" }}
              />
              <ArrowDownwardRounded
                style={{ width: 25, height: 15, borderRadius: "50%" }}
              />
            </IconButton>
          </div>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {process.env.REACT_APP_ADMIN_APP_NAME === "whitelabel" && (
          <MenuItem onClick={(event) => handleMenuItemClick(event, "Profile")}>
            <Avatar /> Profile
          </MenuItem>
        )}

        {process.env.REACT_APP_ADMIN_APP_NAME !== "whitelabel" && (
          <MenuItem
            onClick={(event) => handleMenuItemClick(event, "Myaccount")}
          >
            <Avatar /> My account
          </MenuItem>
        )}

        <Divider />
        {process.env.REACT_APP_ADMIN_APP_NAME === "whitelabel" && (
          <MenuItem
            onClick={(event) => handleMenuItemClick(event, "ChangePassword")}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
        )}

        <MenuItem onClick={(event) => handleMenuItemClick(event, "Logout")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <div className={classes.profile__info}>
        {/* <p className={classes.profile__userName}>{localStorage.getItem('user')}</p>  */}
        {/* <span className={classes.profile__role}>{t("admin")}</span> */}
      </div>
    </>
  );
}
export default ProfileMenu;