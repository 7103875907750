import { useFormik } from "formik";
import { ICommonReturnType, ICreateThemeParams, ICreateThemeProps, ILanguageListProps } from "../../interfaces/interfaceCommon";
import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { InputBox } from "../common/UI/inputBox/inputBox";
import config from "../../constants/config";
import postService from "../../services/post";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import SelectComponent from "../common/UI/select/selectComponent";
import { useEffect, useState } from "react";
import getService from "../../services/getService";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import putService from "../../services/putService";
import { createLanguageValidation } from "../../validations/createLanguageValidation";
import { displayOptions } from "../../constants/data";

export const AddLanguage: React.FC<ICreateThemeProps> = ({ closeModal, modalId, refetch, id }) => {
    const [editRecordData, setEditRecordData] = useState<ILanguageListProps>({} as ILanguageListProps);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        !!id && fetchEditRecords();
    }, []);

    const fetchEditRecords = async () => {
        setIsLoading(true);
        try {
            const url = `${config.cmsConfig.apiEndpoint.editLanguage}/${id}`;
            const result: ICommonReturnType = await getService(url);
            setEditRecordData(result.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }

    }

    const editRecordInitialState = {
        name: editRecordData.name as string || "",
        sort: 0,
        isMobile: !!editRecordData.isMobile,
        displayFor: displayOptions.filter((value) => value.name === editRecordData.displayFor)[0]?.id || -1,
        isActive: !!editRecordData.isActive
    }

    const initialValues = !!id ? editRecordInitialState : {
        name: "",
        sort: 0,
        isMobile: false,
        displayFor: -1,
        isActive: false
    }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, dirty, resetForm } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: createLanguageValidation,
        onSubmit: (values) => {
            const displayForArr = displayOptions.filter((value) => value.id === Number(values.displayFor));
            let param: ICreateThemeParams = {
                name: values.name,
                sort: values.sort,
                isMobile: values.isMobile,
                displayFor: displayForArr[0].name,
                isActive: values.isActive
            };
            mutationMint.mutateAsync(param);
        },
    });

    const mutationMint = useMutation({
        mutationFn: async (param: ICreateThemeParams) => {
            const url = !!id ? `${config.cmsConfig.apiEndpoint.updateLanguage}/${id}` : config.cmsConfig.apiEndpoint.createLanguage;
            try {
                const result = !!id ? await putService(url, param) : await postService(url, param);
                return result;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: (data) => {
            refetch();
            closeModal(modalId);
            toast.success(data.message);
            resetForm();
        },
        onError: (error: any) => {
            toast.error('Failed to create ' + error.response?.data?.message);
        },
    });

    return (isLoading ? <Stack minHeight={300}>{<LoadingSpinner small={true} />}</Stack> :
        <div className="model-inner-margintop">
            <form onSubmit={handleSubmit}>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.name} name="name" id={"name"} label={"Language Name"} variant="outlined" errorMessage={errors.name} isTouched={touched.name} />
                    </Stack>
                    <Stack flex={1}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.sort} name="sort" id={"sort"} label={"Sort"} variant="outlined" errorMessage={errors.sort} isTouched={touched.sort} />
                    </Stack>
                </Stack>
                <Stack marginBottom={2} flexDirection={"row"} gap={2}>
                    <Stack flex={1}>
                        <SelectComponent
                            value={values.displayFor}
                            options={displayOptions}
                            size="medium"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="displayFor"
                            name="displayFor"
                            label="Display for"
                            labelId="displayFor"
                            errorMessage={errors.displayFor}
                            touched={touched.displayFor}
                        />
                    </Stack>
                    <Stack flex={1} marginBottom={2} flexDirection={"row"} gap={2} justifyContent={"center"} alignItems={"center"}
                    marginTop={"1.2rem"}>
                        <FormControlLabel control={
                                   <Checkbox
                                checked={values.isMobile}
                                onChange={handleChange}
                                name="isMobile"
                              
                            />}
                            sx={{
                                "& .MuiCheckbox-root":{
                                    color:"#5688F7"
                                }
                            }}
                            label="Mobile" />
                        <FormControlLabel control={
                               <Checkbox
                                checked={values.isActive}
                                onChange={handleChange}
                                name="isActive"
                            />}
                            sx={{
                                "& .MuiCheckbox-root":{
                                    color:"#5688F7",
                                }
                            }}
                            label="Active" />
                    </Stack>
                </Stack>

                <div className={`button_Wrapper`}>
                    <Button className="customBtn" disabled={!dirty} type="reset" onClick={() => resetForm()} size="small" variant="outlined">
                        {"Reset"}
                    </Button>
                    <Button className="customBtn" disabled={!isValid || !dirty} type="submit" size="small" variant="outlined">
                        {"submit"}</Button>
                </div>
            </form>
        </div >
    )
}